import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import Promise from 'bluebird';

const applications = [
  ['activation-app', () => import ('./App')],
]

const renderApp = async ([id, loader]) => {
  var target = document.getElementById(id);

  if (target) {
    var module = await loader();
    var App = module.default;
    var root = createRoot(target);

    root.render(<App />);
    return true;
  }

  return false;
}

try {
Promise.map( applications, renderApp).then( (loaded) => {
  if (loaded.some( (x) => x )) {
    registerServiceWorker();
  }
});
} catch (error) {
  var message=error.message();
}


